<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="canCancel"
      :is-full-page="fullPage"
      :loader="typeLoader" />
  </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  props: {
    isLoading: Boolean,
    typeLoader: {
      type: String,
      default: 'dots'
    },
    canCancel: Boolean,
    fullPage: Boolean
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
